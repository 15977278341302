import axios from "axios";
import { NGROK } from "../../../APIs";

export const fetchNotifications = async (
  setter,
  fromdetails = false,
  computerName = null,
) => {
  try {
    const response = await axios.get(`${NGROK}/api/access/admin-notifications`);
    let filteredData = response.data;
    if (fromdetails && computerName) {
      filteredData =
        response?.data && response?.data.length > 0
          ? response?.data.filter(
              (item) =>
                item?.computerName?.toLowerCase() ===
                computerName?.toLowerCase(),
            )
          : [];
    }
    setter(filteredData);
  } catch (error) {
    console.error(error);
  }
};
