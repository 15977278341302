import React, { useEffect, useState } from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Tooltip,
  Box,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";
import { formatDateTime } from "../../../../GenericMethods";
import { GrUpgrade } from "react-icons/gr";
import { RxUpdate } from "react-icons/rx";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../../../services/userStore";
import RouterIcon from "@mui/icons-material/Router";

const DevicelistItem = ({
  device,
  upgradeMachine,
  role,
  selectedTenant,
  getComputers,
  handleConnectButton,
}) => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const [upgrading, setUpgrading] = useState(false);
  const operatingSystems = {
    linux: "LINUX",
    windows: "WINDOWS",
    mac: "MAC",
  };
  const setActiveComputer = useUserStore((state) => state.setActiveComputer);

  const os = Object.keys(operatingSystems).find((key) =>
    device.OperatingSystem.toLowerCase().includes(key),
  );

  const osIcon = {
    linux: <FaLinux size={40} color={"#233044"} />,
    windows: <FaWindows size={35} color={"#233044"} />,
    mac: <FaApple size={40} color={"#233044"} />,
  };

  const getLastSeenStatus = (dateTimeStr) => {
    if (dateTimeStr) {
      const isUTC = dateTimeStr.endsWith("Z");

      if (!isUTC) {
        dateTimeStr = dateTimeStr + "Z";
      }
      const dateTime = new Date(dateTimeStr);
      const now = new Date();
      const utcNow = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds(),
        ),
      );

      const diffMs = utcNow - dateTime;
      if (diffMs <= 5 * 60 * 1000) {
        return "online";
      } else {
        return formatDateTime(dateTimeStr);
      }
    } else {
      return "offline";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "online":
        return "green";
      case "-":
        return "grey";
      default:
        return "red";
    }
  };
  const getStatusIndicator = (status) => (
    <Box
      sx={{
        display: "inline-block",
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: getStatusColor(status),
        marginRight: 1,
      }}
    />
  );

  const status = getLastSeenStatus(device.lastSeen);

  const upgradeDevice = (computerId) => {
    let getComputerUpgradeList;
    try {
      getComputerUpgradeList =
        JSON.parse(myLocalStorage.getItem("upgradeComputer")) || [];
    } catch (e) {
      getComputerUpgradeList = [];
    }

    if (!Array.isArray(getComputerUpgradeList)) {
      getComputerUpgradeList = [];
    }

    getComputerUpgradeList.push({
      computerId: computerId,
      time: new Date(),
    });

    myLocalStorage.setItem(
      "upgradeComputer",
      JSON.stringify(getComputerUpgradeList),
    );

    setUpgrading(true);
    upgradeMachine(computerId);
  };

  const checkforComputerUpgradeList = () => {
    let getComputerUpgradeList;
    try {
      getComputerUpgradeList =
        JSON.parse(myLocalStorage.getItem("upgradeComputer")) || [];
    } catch (e) {
      getComputerUpgradeList = [];
    }

    if (!Array.isArray(getComputerUpgradeList)) {
      getComputerUpgradeList = [];
    }
    const computer_item = getComputerUpgradeList.find(
      (computer) => computer.computerId === device.id,
    );

    if (computer_item) {
      if (device.agentUpgradeAvailable) {
        setUpgrading(true);
      } else {
        let filteredArray = getComputerUpgradeList.filter(
          (item) => item.computerId !== device.id,
        );
        myLocalStorage.setItem(
          "upgradeComputer",
          JSON.stringify(filteredArray),
        );
        setUpgrading(false);
      }
    } else {
      setUpgrading(false);
    }
  };
  const getUpgradeStatus = () => {
    let getComputerUpgradeList;
    try {
      getComputerUpgradeList =
        JSON.parse(myLocalStorage.getItem("upgradeComputer")) || [];
    } catch (e) {
      getComputerUpgradeList = [];
    }

    if (!Array.isArray(getComputerUpgradeList)) {
      getComputerUpgradeList = [];
    }
    const computer_item = getComputerUpgradeList.find(
      (computer) => computer.computerId === device.id,
    );
    let currentTime = new Date();
    let status = "Upgrading";
    if (computer_item) {
      if (device.agentUpgradeAvailable) {
        const differenceInMinutes =
          (currentTime - new Date(computer_item.time)) / (1000 * 60);

        if (differenceInMinutes >= 15 && differenceInMinutes <= 30) {
          status = "Slow network";
        } else if (differenceInMinutes > 30) {
          let filteredArray = getComputerUpgradeList.filter(
            (item) => item.computerId !== device.id,
          );
          myLocalStorage.setItem(
            "upgradeComputer",
            JSON.stringify(filteredArray),
          );
          setUpgrading(false);
          status = "Network issue";
        } else {
          status = "Upgrading";
        }
      }
    }
    return status;
  };

  useEffect(() => {
    checkforComputerUpgradeList();
  }, [device, setActiveComputer]);

  return (
    <Stack direction={"row"}>
      <ListItem
        sx={{
          minWidth: 300,
          wordBreak: "break-word",
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: "white" }}>{osIcon[os]}</Avatar>
        </ListItemAvatar>
        <Stack spacing={1}>
          <ListItemText
            primary={
              <Stack spacing={1} direction={"row"} alignItems={"center"}>
                <Typography
                  component="span"
                  sx={{ fontSize: "15px", display: "inline" }}
                >
                  <Button
                    variant="text"
                    sx={{
                      color: "inherit",
                      marginLeft: "-7px",
                      fontSize: "15px",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => {
                      setActiveComputer(device);
                      navigate("deviceDetails", {
                        state: {
                          device: device,
                          selectedTenant: selectedTenant,
                        },
                      });
                    }}
                  >
                    {device.dNSHostName}
                  </Button>
                </Typography>
                {role !== "TENANT_USER" && device.isGateway && (
                  <Box>
                    <Tooltip title="Gateway">
                      <IconButton>
                        <RouterIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Stack>
            }
            secondary={
              <Stack spacing={1} component={"span"}>
                <Typography
                  component="span"
                  sx={{ fontSize: "14px", display: "inline" }}
                  color="text.secondary"
                >
                  {"OS: "}
                  {device.OperatingSystem}
                </Typography>
                <Typography
                  component="span"
                  sx={{ fontSize: "14px", display: "inline" }}
                  color="text.secondary"
                >
                  {"Agent version: "}
                  {device.agentVersion}
                </Typography>

                {!upgrading ? (
                  <Typography
                    component="span"
                    sx={{ fontSize: "14px", display: "inline" }}
                    color="text.secondary"
                  >
                    {status !== "-" && getStatusIndicator(status)}

                    {status === "online" ? (
                      <span>{"Online"}</span>
                    ) : status === "-" ? (
                      <>{"Status: -"}</>
                    ) : (
                      <Tooltip title={`${status}`}>
                        <span>{"Offline"}</span>
                      </Tooltip>
                    )}
                  </Typography>
                ) : (
                  <>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <RxUpdate />
                      <Typography
                        component="span"
                        sx={{ fontSize: "14px", display: "inline" }}
                        color="text.secondary"
                      >
                        <span>{getUpgradeStatus()}</span>
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            }
          />
        </Stack>
      </ListItem>
      {device.agentUpgradeAvailable && !upgrading && role !== "TENANT_USER" && (
        <Box>
          <Tooltip title="Upgrade">
            <IconButton>
              <GrUpgrade size={25} onClick={() => upgradeDevice(device.id)} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Stack>
  );
};

export default DevicelistItem;
