import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Tabs,
  Tab,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Stack,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { NGROK } from "../../../APIs";
import GroupsIcon from "@mui/icons-material/Groups";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SecurityIcon from "@mui/icons-material/Security";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

const Wrapper = styled.div`
  max-height: 500px;
`;

const useStyles = makeStyles({
  root: {
    maxHeight: "500px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AWSUserDetails = () => {
  const classes = useStyles();

  const location = useLocation();
  const { user, groups } = location.state || { user: {}, groups: [] };
  const [rolesDetailsModalOpen, setRolesDetailsModalOpen] = useState(false);
  const [rolesPermissionJson, setRolesPermissionJson] = useState([]);
  const [groupsPermissionJson, setGroupsPermissionJson] = useState([]);
  const [groupsDetailsModalOpen, setGroupsDetailsModalOpen] = useState(false);

  const [policiesDetailsModalOpen, setPoliciesDetailsModalOpen] =
    useState(false);
  const [policiesDetails, setPoliciesDetails] = useState([]);

  const fetchPolicyDocDetails = async (policyArns) => {
    const data = {
      policyArn: policyArns,
    };

    try {
      const response = await axios.post(
        `${NGROK}/api/aws-policies/by-arn`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching policy details:", error);
      return null;
    }
  };

  const detailsofGroups = async (groupId) => {
    try {
      const resp = await axios.get(`${NGROK}/api/aws-groups/${groupId}`);
      const data = resp.data;
      const attachedUserPolicies = data?.AttachedPolicies || [];
      const inlineUserPolicies = data?.InlinePolicies || [];
      const consolidatedPolicies = [
        ...inlineUserPolicies,
        ...attachedUserPolicies,
      ];
      console.log(consolidatedPolicies);
      const policyArns = consolidatedPolicies.map((policy) => policy.PolicyArn);
      const policyDocDetails = await fetchPolicyDocDetails(policyArns);
      const { GroupId, GroupName, CreatedAt } = data;
      const formattedPolicies = policyDocDetails.map((policy) => ({
        PolicyId: policy.PolicyId,
        PolicyName: policy.PolicyName,
        PolicyArn: policy.PolicyArn,
        PolicyDocument: policy.PolicyDocument,
      }));
      const combinedJson = [
        {
          GroupId,
          GroupName,
          CreatedAt,
          policies: formattedPolicies,
        },
      ];
      setGroupsPermissionJson(combinedJson);
      setGroupsDetailsModalOpen(true);
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
  };

  const detailsofRoles = async (roleID) => {
    try {
      const resp = await axios.get(`${NGROK}/api/aws-roles/${roleID}`);
      const data = resp.data;
      const attachedUserPolicies = data?.AttachedPermissions || [];
      const inlineUserPolicies = data?.InLinePermissions || [];
      const consolidatedPolicies = [
        ...inlineUserPolicies,
        ...attachedUserPolicies,
      ];

      const policyArns = consolidatedPolicies.map((policy) => policy.PolicyArn);

      const policyDocDetails = await fetchPolicyDocDetails(policyArns);

      const { RoleId, RoleName, CreatedDate } = data;
      const formattedPolicies = policyDocDetails.map((policy) => ({
        PolicyId: policy.PolicyId,
        PolicyName: policy.PolicyName,
        PolicyArn: policy.PolicyArn,
        PolicyDocument: policy.PolicyDocument,
      }));
      const combinedJson = [
        {
          RoleId,
          RoleName,
          CreatedDate,
          policies: formattedPolicies,
        },
      ];
      setRolesPermissionJson(combinedJson);
      setRolesDetailsModalOpen(true);
    } catch (error) {
      console.error("Error fetching role details:", error);
    }
  };

  const detailsofPermission = async (ploicyARN) => {
    const policyDocDetails = await fetchPolicyDocDetails(ploicyARN);
    const formattedPolicies = policyDocDetails.map((policy) => ({
      PolicyId: policy.PolicyId,
      PolicyName: policy.PolicyName,
      PolicyArn: policy.PolicyArn,
      PolicyDocument: policy.PolicyDocument,
    }));
    setPoliciesDetails(formattedPolicies);
    setPoliciesDetailsModalOpen(true);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const consolidatingPolicies = (permissions) => {
    const attachedUserPolicies = permissions?.AttachedUserPolicies || [];
    const inlineUserPolicies = permissions?.InlineUserPolicies || [];
    const groupPolicies = permissions?.GroupPolicies || [];
    const groupInlinePolicies = permissions?.GroupInlinePolicies || [];

    const consolidations = permissions
      ? [
          ...attachedUserPolicies,
          ...inlineUserPolicies,
          ...groupPolicies,
          ...groupInlinePolicies,
        ]
      : [];
    return consolidations;
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Paper elevation={4} sx={{ padding: 3, borderRadius: 5 }}>
          <Box sx={{ marginBottom: 2, borderRadius: 3 }}>
            <Box>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "16px" }}
              >
                {user.UserName}
                <IconButton size="small" sx={{ marginLeft: 1 }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Typography>
              <Stack sx={{ maxWidth: 350 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", marginTop: 1, fontSize: "16px" }}
                >
                  Groups:
                </Typography>
                {user.Groups?.slice(0, 2).map((group, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{ marginLeft: 2, marginTop: 0.5, fontSize: "16px" }}
                  >
                    {group.GroupName} ({group.GroupId})
                  </Typography>
                ))}
                {user.Groups?.length > 2 && (
                  <Tooltip
                    title={user.Groups.map((group) => (
                      <div key={group.GroupId}>
                        {group.GroupName} ({group.GroupId})
                      </div>
                    ))}
                    placement="top"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 2,
                        marginTop: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Typography>+{user.Groups.length - 2} more</Typography>
                      <ExpandMoreIcon
                        fontSize="small"
                        sx={{ marginLeft: 0.5 }}
                      />
                    </Box>
                  </Tooltip>
                )}
                <Divider sx={{ marginY: 2 }} />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Roles:
                </Typography>
                {user.Roles?.slice(0, 2).map((role, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{ marginLeft: 2, marginTop: 0.5, fontSize: "16px" }}
                  >
                    {role.RoleName}
                  </Typography>
                ))}
                {user.Roles?.length > 2 && (
                  <Tooltip
                    title={user.Roles.map((role) => (
                      <div key={role.RoleId}>{role.RoleName}</div>
                    ))}
                    placement="top"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 2,
                        marginTop: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Typography>+{user.Roles.length - 2} more</Typography>
                      <ExpandMoreIcon
                        fontSize="small"
                        sx={{ marginLeft: 0.5 }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            </Box>
          </Box>

          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab
              icon={<GroupsIcon />}
              label="Groups"
              sx={{
                fontSize: "16px",
              }}
              {...a11yProps(0)}
            />
            <Tab
              icon={<AssignmentIcon />}
              label="Roles"
              sx={{
                fontSize: "16px",
              }}
              {...a11yProps(1)}
            />
            <Tab
              icon={<SecurityIcon />}
              label="Permissions"
              sx={{
                fontSize: "16px",
              }}
              {...a11yProps(2)}
            />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <TableContainer
              component={Paper}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Table
                sx={{
                  width: "70%",
                  "& th": { background: "#233044", color: "#fff" },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "16px",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Group Name</TableCell>
                    <TableCell>Group ID</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.Groups?.map((group, index) => (
                    <TableRow
                      key={index}
                      onClick={() => detailsofGroups(group.GroupId)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{group.GroupName}</TableCell>
                      <TableCell>{group.GroupId}</TableCell>
                      <TableCell>
                        {formatDate(
                          groups?.find((g) => g.GroupName === group.GroupName)
                            ?.CreateDate || "N/A",
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <TableContainer
              component={Paper}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Table
                sx={{
                  width: "80%",
                  "& th": { background: "#233044", color: "#fff" },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "16px",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Role Name</TableCell>
                    <TableCell>Role ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.Roles?.map((role, index) => (
                    <TableRow
                      key={index}
                      onClick={() => detailsofRoles(role.RoleId)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{role.RoleName}</TableCell>
                      <TableCell>{role.RoleId}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <TableContainer
              component={Paper}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Table
                sx={{
                  width: "80%",
                  "& th": { background: "#233044", color: "#fff" },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "16px",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Permission Name</TableCell>
                    <TableCell>Policy ARN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consolidatingPolicies(user).map((policy, index) => (
                    <TableRow
                      key={index}
                      onClick={() => detailsofPermission(policy)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{policy.split("/").pop()}</TableCell>
                      <TableCell>{policy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Paper>
      </Box>

      <Dialog
        open={groupsDetailsModalOpen}
        onClose={() => setGroupsDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Groups Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              {groupsPermissionJson.map((group, index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Group Name: </strong> {group.GroupName}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Group ID: </strong> {group.GroupId}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong> Created Date:</strong> {group.CreatedAt}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    <strong>Policies: </strong>
                  </Typography>
                  <List>
                    {group.policies.map((policy, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={`Policy Name :${policy.PolicyName}`}
                            secondary={`Policy ID: ${policy.PolicyId}`}
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          />
                        </ListItem>
                        <Typography sx={{ fontSize: 16, mt: 2 }}>
                          <strong>Policy Document:</strong>
                        </Typography>
                        {policy.PolicyDocument && (
                          <ListItem
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          >
                            <Paper
                              elevation={3}
                              style={{ padding: "5px", margin: "5px" }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>Version:</strong>{" "}
                                {policy.PolicyDocument.Version}
                              </Typography>
                              {policy.PolicyDocument.Statement.map(
                                (statement, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: 16 }}
                                      >
                                        <strong>Statement {index + 1}:</strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Effect:</strong>{" "}
                                        {statement.Effect}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Action:</strong>{" "}
                                        <List disablePadding>
                                          {statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Resource:</strong>{" "}
                                        {Array.isArray(statement.Resource) ? (
                                          <List>
                                            {statement.Resource.map(
                                              (resource, index) => (
                                                <ListItem
                                                  key={index}
                                                  disableGutters
                                                  sx={{ padding: 0 }}
                                                >
                                                  <ListItemText>
                                                    <Typography
                                                      sx={{ fontSize: 16 }}
                                                    >
                                                      {resource}
                                                    </Typography>
                                                  </ListItemText>
                                                </ListItem>
                                              ),
                                            )}
                                          </List>
                                        ) : (
                                          <Typography sx={{ fontSize: 16 }}>
                                            {statement.Resource}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </Paper>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setGroupsDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={rolesDetailsModalOpen}
        onClose={() => setRolesDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Role Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              {rolesPermissionJson.map((role, index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Role Name: </strong> {role.RoleName}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>Role ID: </strong> {role.RoleId}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "16px" }}
                  >
                    <strong> Created Date:</strong> {role.CreatedDate}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    <strong>Policies: </strong>
                  </Typography>
                  <List>
                    {role.policies.map((policy, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={`Policy Name :${policy.PolicyName}`}
                            secondary={`Policy ID: ${policy.PolicyId}`}
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          />
                        </ListItem>
                        <Typography sx={{ fontSize: 16, mt: 2 }}>
                          <strong>Policy Document:</strong>
                        </Typography>
                        {policy.PolicyDocument && (
                          <ListItem
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                          >
                            <Paper
                              elevation={3}
                              style={{ padding: "5px", margin: "5px" }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>Version:</strong>{" "}
                                {policy.PolicyDocument.Version}
                              </Typography>
                              {policy.PolicyDocument.Statement.map(
                                (statement, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: 16 }}
                                      >
                                        <strong>Statement {index + 1}:</strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Effect:</strong>{" "}
                                        {statement.Effect}
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Action:</strong>{" "}
                                        <List disablePadding>
                                          {statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      </Typography>
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>Resource:</strong>{" "}
                                        {Array.isArray(statement.Resource) ? (
                                          <List>
                                            {statement.Resource.map(
                                              (resource, index) => (
                                                <ListItem
                                                  key={index}
                                                  disableGutters
                                                  sx={{ padding: 0 }}
                                                >
                                                  <ListItemText>
                                                    <Typography
                                                      sx={{ fontSize: 16 }}
                                                    >
                                                      {resource}
                                                    </Typography>
                                                  </ListItemText>
                                                </ListItem>
                                              ),
                                            )}
                                          </List>
                                        ) : (
                                          <Typography sx={{ fontSize: 16 }}>
                                            {statement.Resource}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </Paper>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRolesDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={policiesDetailsModalOpen}
        onClose={() => setPoliciesDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Policy Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "16px" }}>
                  <strong>Policies: </strong>
                </Typography>
                <List>
                  {policiesDetails.map((policy, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={policy.PolicyName}
                          secondary={`Policy ID: ${policy.PolicyId}`}
                          primaryTypographyProps={{ sx: { fontSize: 16 } }}
                        />
                      </ListItem>
                      <Typography sx={{ fontSize: 16, mt: 2 }}>
                        <strong>Policy Document:</strong>
                      </Typography>
                      {policy.PolicyDocument && (
                        <ListItem
                          primaryTypographyProps={{ sx: { fontSize: 16 } }}
                        >
                          <Paper
                            elevation={3}
                            style={{ padding: "5px", margin: "5px" }}
                          >
                            <Typography sx={{ fontSize: 16 }}>
                              <strong>Version:</strong>{" "}
                              {policy.PolicyDocument.Version}
                            </Typography>
                            {policy.PolicyDocument.Statement.map(
                              (statement, index) => (
                                <Grid
                                  container
                                  key={index}
                                  spacing={2}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontSize: 16 }}
                                    >
                                      <strong>Statement {index + 1}:</strong>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Sid:</strong> {statement.Sid}
                                    </Typography>
                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Effect:</strong>{" "}
                                      {statement.Effect}
                                    </Typography>
                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Action:</strong>{" "}
                                      <List disablePadding>
                                        {statement.Action?.map(
                                          (action, index) => (
                                            <ListItem
                                              key={index}
                                              disableGutters
                                              sx={{ padding: 0 }}
                                            >
                                              <ListItemText>
                                                <Typography
                                                  sx={{ fontSize: 16 }}
                                                >
                                                  {action}
                                                </Typography>
                                              </ListItemText>
                                            </ListItem>
                                          ),
                                        )}
                                      </List>
                                    </Typography>
                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Resource:</strong>{" "}
                                      {Array.isArray(statement.Resource) ? (
                                        <List>
                                          {statement.Resource.map(
                                            (resource, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 16 }}
                                                  >
                                                    {resource}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      ) : (
                                        <Typography sx={{ fontSize: 16 }}>
                                          {statement.Resource}
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ),
                            )}
                          </Paper>
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPoliciesDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AWSUserDetails;
