import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";

import {
  CircularProgress,
  Table,
  TableContainer,
  Box,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { myLocalStorage } from "../../../components/StorageHelper";
import ConfirmAlert from "../../components/ConfirmAlert";
import TenantSelection from "../Components/TenantSelection";
import EndpointsTableHead from "./components/EndpointsTableHead";
import EndpointsTableBody from "./components/EndpointsTableBody";
import { fetchTenantsData } from "../api";
import SearchField from "../logs/components/SearchField";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import { checkIfUserHassAccess } from "../../../services/Helpers";
import { useNavigate } from "react-router-dom";
import { Delete as DeleteIcon } from "@mui/icons-material";
import RouterIcon from "@mui/icons-material/Router";

const Endpoints = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const [tenantsList, setTenantsList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [tableMaxHeight, setTableMaxHeight] = useState("100%");
  const [tableMaxWidth, setTableMaxWidth] = useState("100%");
  const [selectedTenant, setSelectedTenant] = useState(
    myLocalStorage.getItem("latestTenant"),
  );
  const [openDeleteDeviceAlert, setOpenDeleteDeviceAlert] = useState(false);
  const [selectedMachines, setSelectedMachines] = useState(null);
  const [openDeviceGateway, setOpenDeviceGateway] = useState(false);
  const [ipGatewayConfig, setIpGatewayConfig] = useState([]);

  const { role, email, userId } = useUserStore((state) => state.user);
  const activeComputer = useUserStore((state) => state.activeComputer);
  const setComputerId = useUserStore((state) => state.setComputerId);
  const setActiveComputer = useUserStore((state) => state.setActiveComputer);

  const fetchDataProfiles = async (computerId) => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/computers/${computerId}/profiles`,
    );
    setProfiles(response.data);
    return response.data;
  };

  const getUserByEmail = async (deviceId, email) => {
    const { data: users } = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/computer-users/${deviceId}/users?email=${email}`,
    );

    return users.find((user) => user.email === email);
  };

  const getUserGroups = async (userId) => {
    const {
      data: { content: loginedUserGroups },
    } = await axios.get(
      `${NGROK}/api/${selectedTenant?.tenantName}/computer-user-groups/membership/${userId}`,
    );
    return loginedUserGroups;
  };

  const checkAccessByGroup = async (deviceList) => {
    const devicesWithAccessGroupId = await Promise.all(
      deviceList.map(async (device) => {
        try {
          const computerOwnerUser = await getUserByEmail(device.id, email);

          if (!computerOwnerUser) {
            const whiteswanAccessGroup = await getGroupOfNotComputerOwner(
              device.id,
            );

            if (checkIfUserHassAccess(whiteswanAccessGroup)) {
              return {
                ...device,
                hasAccess: true,
                whiteswanAccessGroupId: whiteswanAccessGroup.id,
                userId: userId,
                isWhiteswanAccess: true,
                accessRemainingTime: whiteswanAccessGroup.remainingTime,
              };
            }

            return {
              ...device,
              whiteswanAccessGroupId: whiteswanAccessGroup?.id,
              isWhiteswanAccess: true,
            };
          } else {
            const computerOwnerUserGroups = await getUserGroups(
              computerOwnerUser.id,
            );

            const whiteswanAccessGroup = computerOwnerUserGroups?.find(
              (group) => group.adComputerUserGroup.cn === "Whiteswan Access",
            );

            if (checkIfUserHassAccess(whiteswanAccessGroup)) {
              return {
                ...device,
                hasAccess: true,
                whiteswanAccessGroupId:
                  whiteswanAccessGroup.adComputerUserGroup.id,
                userId: computerOwnerUser.id,
                accessRemainingTime: whiteswanAccessGroup.remainingTime,
              };
            } else {
              return {
                ...device,
                hasAccess: false,
                whiteswanAccessGroupId:
                  whiteswanAccessGroup?.adComputerUserGroup.id,
              };
            }
          }
        } catch (error) {
          console.error(`Error processing device ${device.id}:`, error);
          return {
            ...device,
          };
        }
      }),
    );
    return devicesWithAccessGroupId;
  };

  const getComputers = async (tenantName) => {
    try {
      const response = await axios.get(`${NGROK}/api/${tenantName}/computers`);
      if (response.data?.length) {
        const devicesSortedById = response.data.sort((a, b) =>
          a.id > b.id ? 1 : -1,
        );

        const newDeviceList = await checkAccessByGroup(devicesSortedById);
        setDeviceList(newDeviceList);
      } else {
        setDeviceList([]);
      }
    } catch (error) {
      setDeviceList([]);
      console.error("computers error", error);
    }
  };

  const getGroupOfNotComputerOwner = async (deviceId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenant.tenantName}/whiteswan-access?computerId=${deviceId}&userId=${userId}`,
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching group for device ${deviceId}:`, error);
    }
  };

  const updateDeviceProfile = async (event, device, defaultProfile) => {
    const profileName = defaultProfile ? defaultProfile : event.target.value;
    const profile = profiles?.find((el) => el.name === profileName);

    if (profile) {
      const updatedDevice = { ...device, profile: profileName };
      const updatedDevices = deviceList.map((element, index) =>
        element.id === updatedDevice.id
          ? (deviceList[index] = updatedDevice)
          : element,
      );
      setDeviceList(updatedDevices);

      await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}/update-computer`,
      );
      await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}`,
      );
    }
  };

  const updateDeviceOperationMode = async (event, device, defaultMode) => {
    const newMode = defaultMode ? defaultMode : event.target.value;
    const updatedDevice = { ...device, mode: newMode };

    const updatedDevices = deviceList.map((element, index) =>
      element.id === updatedDevice.id
        ? (deviceList[index] = updatedDevice)
        : element,
    );
    setDeviceList(updatedDevices);

    await axios.put(`${NGROK}/api/computers/${device.id}/operation-mode`, {
      mode: newMode,
      email,
    });
  };

  const updateTenantOperationMode = async (event, tenant) => {
    const newMode = event.target.value;
    const updatedTenant = { ...tenant, mode: newMode };

    setSelectedTenant(updatedTenant);

    await axios.put(`${NGROK}/api/${tenant.tenantName}/operation-mode`, {
      mode: newMode,
    });
  };

  const createGuacConnection = async (computer) => {
    try {
      const response = await axios.post(
        `${NGROK}/api/guacamole/create-connection`,
        {
          computerId: computer.id,
          selectedTime: computer.accessRemainingTime,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getToken = async (email, computerId, userRole) => {
    try {
      return await axios.get(
        `${NGROK}/api/access/${userRole}/get-token?email=${email}&computerId=${computerId}`,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const saveToken = async (email, computer, userRole) => {
    try {
      return await axios.post(`${NGROK}/api/access/${userRole}/save-token`, {
        email,
        computerId: computer.id,
        groupId: computer.whiteswanAccessGroupId,
        userId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const connectComputer = async (computer, userRole) => {
    const connectionData = await createGuacConnection(computer);
    if (!connectionData) return;

    const inputData = `${connectionData.identifier}\0c\0postgresql`;
    const mqString = btoa(inputData);

    const response = await getToken(email, computer.id, userRole);
    const { authToken } = response?.data;

    if (authToken) {
      window.open(
        `https://${computer.guachost}/#/client/${mqString}?token=${authToken}`,
        "_blank",
      );
    } else {
      const {
        data: { authToken },
      } = await saveToken(email, computer, userRole);

      window.open(
        `https://${computer.guachost}/#/client/${mqString}?token=${authToken}`,
        "_blank",
      );
    }
  };

  const handleConnectButton = (computer) => {
    if (role === "TENANT_USER") connectComputer(computer, "user");
    else connectComputer(computer, "admin");
  };

  const toggleRecording = async (computer) => {
    const connectionData = await createGuacConnection(computer);
    if (!connectionData) return;

    const newRecordingStatus = computer.recordingPathEnabled ? false : true;

    const toggleRecordingLocally = () => {
      const updatedComputer = {
        ...computer,
        recordingPathEnabled: newRecordingStatus,
      };
      setDeviceList(
        deviceList.map((el) =>
          el.id === updatedComputer.id ? updatedComputer : el,
        ),
      );
    };
    toggleRecordingLocally();

    try {
      await axios.put(`${NGROK}/api/guacamole/update-connection`, {
        url: `https://${computer.guachost}`,
        id: connectionData.id,
        enableRecordingPath: newRecordingStatus,
        selectedTime: computer.accessRemainingTime,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateDevicesAfterDeleting = (id) => {
    setSelectedMachines(null);
    setDeviceList((prev) => prev.filter((device) => device.id !== id));
  };

  useEffect(() => {
    if (tenantsList.length === 1) return setSelectedTenant(tenantsList[0]);

    if (tenantsList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantsList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );

      if (tenant) setSelectedTenant(tenant);
      else setSelectedTenant(tenantsList[0]);
    }
  }, [tenantsList]);

  useEffect(() => {
    if (selectedTenant) {
      myLocalStorage.setItem("latestTenant", selectedTenant); // store tenant in case of reload

      getComputers(selectedTenant?.tenantName);
      const interval = setInterval(() => {
        getComputers(selectedTenant?.tenantName);
      }, 10000);
      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant]);

  const upgradeMachine = (computerId) => {
    const response = axios.post(`${NGROK}/api/agent/update`, {
      computer_id: computerId,
    });
    response
      .then(() => {
        getComputers(selectedTenant?.tenantName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (email) fetchTenantsData(email, setTenantsList);
  }, [email]);

  const allowedSearchFields = [
    "dNSHostName",
    "OperatingSystem",
    "agentVersion",
    "profile",
  ];

  const {
    searchTerm,
    filteredData: filteredDevices,
    handleSearch,
  } = useGeneralSearch(deviceList, allowedSearchFields);

  const isEveryMachinesSelected = false;
  const selectAllMachines = () => {
    const allMachinesAlreadySelected =
      selectedMachines?.length === filteredDevices.length;

    if (allMachinesAlreadySelected) setSelectedMachines([]);
    else setSelectedMachines(filteredDevices);
  };

  const checkIfMachineSelected = (deviceId) =>
    deviceId === selectedMachines?.id;

  const handleDeviceSelect = (device) => {
    if (device.id === selectedMachines?.id) setSelectedMachines(null);
    else setSelectedMachines(device);
  };
  useLayoutEffect(() => {
    if (tableRef.current) {
      const screenHeight = window.innerHeight;
      const rect = tableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top + window.pageYOffset;
      let maxHeight = screenHeight - (distanceFromTop + 35);
      setTableMaxWidth(window.innerWidth + "px");
      setTableMaxHeight(maxHeight + "px");
    }
  }, [tableRef.current]);
  const filteredIPs = selectedMachines?.IPV4Addresses.filter(
    (ip) => !ip.startsWith("100.64"),
  );

  const handleCheckBox = (ip) => {
    if (ipGatewayConfig.includes(ip)) {
      setIpGatewayConfig((prevConfig) =>
        prevConfig.filter((item) => item !== ip),
      );
    } else {
      setIpGatewayConfig((prevConfig) => [...prevConfig, ip]);
    }
  };

  const isSubmitButtonEnabled = () => {
    const gatewayIpAddress = selectedMachines?.gatewayIpAddresses || [];
    const ipGatewayConfigSet = new Set(ipGatewayConfig);
    const gatewayIpAddressSet = new Set(gatewayIpAddress);
    const addedIps = [...gatewayIpAddressSet].filter(
      (ip) => !ipGatewayConfigSet.has(ip),
    );
    const removedIps = [...ipGatewayConfigSet].filter(
      (ip) => !gatewayIpAddressSet.has(ip),
    );

    return addedIps.length === 0 && removedIps.length === 0;
  };
  const configureDeviceIPAddress = async () => {
    try {
      await axios.post(`${NGROK}/api/set-gateway`, {
        computerId: selectedMachines?.id,
        gatewayIpAddresses: ipGatewayConfig,
      });
    } catch (e) {
      console.error(e);
    }
    setSelectedMachines(null);
    setOpenDeviceGateway(false);
    getComputers(selectedTenant?.tenantName);
  };

  return (
    <Box width={"100%"}>
      {activeComputer ? (
        <ConfirmAlert
          updateDevicesAfterDeleting={updateDevicesAfterDeleting}
          deviceId={activeComputer.id}
          open={openDeleteDeviceAlert}
          setOpen={setOpenDeleteDeviceAlert}
          headerText={`Are you sure, you want to delete this computer - ${
            activeComputer
              ? activeComputer.cn ||
                activeComputer.dNSHostName ||
                "unknown computer"
              : ""
          }?`}
        />
      ) : null}
      <Stack spacing={5}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            width: "97%",
          }}
        >
          <Stack direction={"row"} spacing={4}>
            <TenantSelection
              selectedTenant={selectedTenant}
              setSelectedTenant={setSelectedTenant}
            />
            {/*  {selectedTenant ? (
            <FormControl sx={{ display: "flex", alignItems: "center" }}>
              <InputLabel id="demo-simple-select-label">Mode</InputLabel>
              <Select
                sx={{ minWidth: "100px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Operation Mode"
                value={selectedTenant.mode || " "}
                size="medium"
                onChange={(event) =>
                  updateTenantOperationMode(event, selectedTenant)
                }
              >
                <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
                <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
                <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
                );
              </Select>
            </FormControl>
          ) : null} */}
            <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
          </Stack>

          <Stack spacing={2} direction="row">
            {role !== "TENANT_USER" ? (
              <>
                <Button
                  size="small"
                  sx={{ fontSize: "16px" }}
                  variant="outlined"
                  color="primary"
                  disabled={
                    !selectedMachines ||
                    selectedMachines?.id === 1 ||
                    !selectedMachines?.OperatingSystem.toLowerCase().includes(
                      "linux",
                    )
                  }
                  startIcon={<RouterIcon />}
                  onClick={() => {
                    setActiveComputer(selectedMachines);
                    setOpenDeviceGateway(true);
                    setIpGatewayConfig(selectedMachines?.gatewayIpAddresses);
                  }}
                >
                  Gateway
                </Button>
              </>
            ) : null}
            {role !== "TENANT_USER" ? (
              <Button
                size="small"
                sx={{ fontSize: "16px" }}
                variant="outlined"
                color="error"
                disabled={!selectedMachines || selectedMachines?.id === 1}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setActiveComputer(selectedMachines);
                  setOpenDeleteDeviceAlert(!openDeleteDeviceAlert);
                }}
              >
                Delete
              </Button>
            ) : null}
          </Stack>
        </Box>
        {deviceList?.length ? (
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "1px solid #233044",
            }}
          >
            <TableContainer
              sx={{
                height: "fit-content",
                width: "100%",
                maxHeight: tableMaxHeight,
              }}
              ref={tableRef}
            >
              <Table
                stickyHeader
                aria-label="simple table"
                sx={{
                  height: "fit-content",
                  "& td, & th": {
                    border: "1px solid #233044",
                  },
                  "& th": {
                    backgroundColor: "#233044",
                  },
                }}
              >
                <EndpointsTableHead
                  role={role}
                  isEveryMachinesSelected={isEveryMachinesSelected}
                  selectAllMachines={selectAllMachines}
                />
                <EndpointsTableBody
                  getComputers={getComputers}
                  deviceList={filteredDevices}
                  updateDeviceProfile={updateDeviceProfile}
                  updateDeviceOperationMode={updateDeviceOperationMode}
                  handleConnectButton={handleConnectButton}
                  toggleRecording={toggleRecording}
                  profiles={profiles}
                  setProfiles={setProfiles}
                  fetchDataProfiles={fetchDataProfiles}
                  selectedTenant={selectedTenant}
                  setOpenDeleteDeviceAlert={setOpenDeleteDeviceAlert}
                  openDeleteDeviceAlert={openDeleteDeviceAlert}
                  upgradeMachine={upgradeMachine}
                  checkIfMachineSelected={checkIfMachineSelected}
                  handleDeviceSelect={handleDeviceSelect}
                />
              </Table>
            </TableContainer>
          </Paper>
        ) : deviceList === undefined ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Box pl={5}>
            <Typography
              component={"span"}
              sx={{ fontSize: "20px", fontWeight: "500" }}
            >
              There are no computers.
            </Typography>
          </Box>
        )}
      </Stack>
      {openDeviceGateway && (
        <>
          <Dialog
            open={openDeviceGateway}
            onClose={() => setOpenDeviceGateway(false)}
            fullWidth
          >
            <DialogTitle>Configure Gateway</DialogTitle>
            <DialogContent>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 40 }} align="left">
                      Sl No
                    </TableCell>
                    <TableCell align="left">IP Address</TableCell>
                    <TableCell align="left">Configure</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredIPs.length > 0 ? (
                    filteredIPs.map((ip, index) => (
                      <TableRow key={`${ip}  ${index}`}>
                        <TableCell component="td" scope="row" align="left">
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            wordWrap: "break-word",
                            maxWidth: "260px",
                          }}
                          align="left"
                        >
                          {ip}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            onChange={() => handleCheckBox(ip)}
                            checked={ipGatewayConfig?.includes(ip)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} rowSpan={3}>
                        <Typography variant="span">
                          No Ip Address found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenDeviceGateway(false)}
                color="error"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setOpenDeviceGateway(false);
                  configureDeviceIPAddress();
                }}
                disabled={isSubmitButtonEnabled()}
                color="primary"
                autoFocus
                variant="outlined"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default Endpoints;
