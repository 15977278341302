import React, { useEffect, useState } from "react";

import RequestsTable from "./Components/RequestsTable";
import { Box, Button, Stack } from "@mui/material";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import useNotificationStore from "../../../services/notificationStore";
import SearchField from "../logs/components/SearchField";
import CustomDatePicker from "../logs/components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { handleGeneratePDF } from "./helpers";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";

const ResourceRequests = ({ fromdetails = false, computerName = null }) => {
  const tenant = myLocalStorage.getItem("latestTenant");

  const { adminNotifications, setAdminNotifications } = useNotificationStore();
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const allowedSearchFields = [
    "createdAt",
    "email",
    "computerName",
    "resourcePath",
    "requestStatus",
    "privilegeLevel",
  ];
  const {
    searchTerm,
    filteredData: notificationsFilteredBySearch,
    handleSearch,
  } = useGeneralSearch(adminNotifications, allowedSearchFields);

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: filteredNotifications,
  } = useDateFilter(notificationsFilteredBySearch, "createdAt");

  const tenantNotifications =
    filteredNotifications?.filter(
      (notification) => notification.tenantName === selectedTenant?.tenantName,
    ) || [];
  useEffect(() => {
    setAdminNotifications([]);
  }, []);

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!fromdetails ? (
          <TenantSelection
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
          />
        ) : (
          <Box></Box>
        )}

        <Stack direction={"row"} spacing={2}>
          <CustomDatePicker
            dateRange={dateRange}
            handleChangeDateRange={handleChangeDateRange}
          />

          <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />

          <Button
            variant="outlined"
            onClick={() =>
              handleGeneratePDF(dateRange, tenantNotifications, searchTerm)
            }
            disabled={tenantNotifications.length === 0}
          >
            Download Report
          </Button>
        </Stack>
      </Box>

      <RequestsTable
        adminNotifications={tenantNotifications}
        setAdminNotifications={setAdminNotifications}
        fromdetails={fromdetails}
        computerName={computerName}
      />
    </Stack>
  );
};

export default ResourceRequests;
