import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { NGROK } from "../../../APIs";

const AWSUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const getAllUsers = async () => {
    const response = await axios.get(`${NGROK}/api/aws-users`);
    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(`${NGROK}/api/aws-groups`);
    return response.data;
  };

  const getAllRoles = async () => {
    const response = await axios.get(`${NGROK}/api/aws-roles`);
    return response.data;
  };

  const fetchData = async () => {
    try {
      const [users, groups, roles] = await Promise.all([
        getAllUsers(),
        getAllGroups(),
        getAllRoles(),
      ]);
      const combineData = {
        Users: users,
        Groups: groups,
        Roles: roles,
      };
      setUsersData(combineData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const MAX_ITEMS_DISPLAYED = 2;

  const renderItemsWithMoreLink = (items, maxItems, user, groups) => {
    if (items.length <= maxItems) {
      return items.join(", ");
    } else {
      const displayedItems = items.slice(0, maxItems).join(", ");
      const remainingCount = items.length - maxItems;
      return (
        <>
          {displayedItems},{" "}
          <Link to={`/aws/users/userDetails`} state={{ user, groups }}>
            {remainingCount} more
          </Link>
        </>
      );
    }
  };

  const renderItemsWithMoreLinkAndConsolidating = (
    permissions,
    maxItems,
    user,
    groups,
  ) => {
    const items = consolidatepermissions(permissions);

    if (items.length <= maxItems) {
      return items.join(", ");
    } else {
      const displayedItems = items.slice(0, maxItems).join(", ");
      const remainingCount = items.length - maxItems;
      return (
        <>
          {displayedItems},{" "}
          <Link to={`/aws/users/userDetails`} state={{ user, groups }}>
            {remainingCount} more
          </Link>
        </>
      );
    }
  };

  const consolidatepermissions = (permissions) => {
    const consolidations = permissions
      ? [
          ...permissions.AttachedUserPolicies,
          ...permissions.InlineUserPolicies,
          ...permissions.GroupPolicies,
          ...permissions.GroupInlinePolicies,
        ]
      : [];
    return consolidations;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = usersData
    ? usersData.Users.filter((user) =>
        user.UserName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  if (loading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  if (!usersData) {
    return <div>No data available</div>;
  }

  return (
    <Box>
      <Stack spacing={5}>
        <Box display="flex" justifyContent="flex-end">
          <TextField
            placeholder="Search users..."
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {filteredUsers.length === 0 ? (
          <Typography
            variant="body2"
            color="textSecondary"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            No Users available
          </Typography>
        ) : (
          <TableContainer style={{ height: "fit-content" }} component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                maxWidth: "100%",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "16px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">User Name</TableCell>
                  <TableCell align="center">Groups</TableCell>
                  <TableCell align="center">Roles</TableCell>
                  <TableCell align="center">Created At</TableCell>
                  <TableCell align="center">Permissions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => (
                  <TableRow key={user.UserName}>
                    <TableCell align="center">
                      <Link
                        to={`/aws/users/userDetails`}
                        state={{ user, groups: usersData.Groups }}
                      >
                        {user.UserName}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {renderItemsWithMoreLink(
                        user.Groups.map((group) => group.GroupName),
                        MAX_ITEMS_DISPLAYED,
                        user,
                        usersData.Groups,
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderItemsWithMoreLink(
                        user.Roles.map((role) => role.RoleName),
                        MAX_ITEMS_DISPLAYED,
                        user,
                        usersData.Groups,
                      )}
                    </TableCell>
                    <TableCell align="center">{"N/A"}</TableCell>
                    <TableCell align="center">
                      {renderItemsWithMoreLinkAndConsolidating(
                        user,
                        MAX_ITEMS_DISPLAYED,
                        user,
                        usersData.Groups,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
};

export default AWSUsers;
